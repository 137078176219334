<template>
  <v-dialog
    v-if="isActive && showAttributesPage"
    v-model="isActive"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-card-title>
        {{ getLabel(questions[0].question.title) }}
      </v-card-title>
      <v-divider></v-divider>
      <v-container class="mt-4" style="position: relative">
        <v-slide-x-reverse-transition model="in-out">
          <template v-for="(question, idx) in questions">
            <v-card
              :key="idx"
              v-if="idx == questionIndex"
              style="position: absolute; top: 0; left: 0; right: 0"
            >
              <v-card-title> {{ getLabel(question.name) }} </v-card-title>
              <v-card-actions>
                <v-container>
                  <v-row
                    dense
                    v-for="(option, idx) in question.options"
                    :key="idx"
                  >
                    <v-col>
                      <v-btn
                        x-large
                        color="primary"
                        block
                        outlined
                        @click="answer(question.attribute, option.value)"
                        class="justify-start"
                      >
                        {{ getLabel(option.name) }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>

                <!-- <v-btn block @click="showQuestions = false"> 送信 </v-btn> -->
              </v-card-actions>
            </v-card>
          </template>
        </v-slide-x-reverse-transition>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
// library
import Questions from "@/library/Questions";
export default {
  props: {
    value: Object, //guest
    hotel: Object,
    show: Boolean,
  },
  data: () => ({
    questionIndex: 0,
  }),
  computed: {
    isActive() {
      return !!this.guest && !this.guest.answered_at;
    },
    showAttributesPage() {
      return this.hotel.show_guest_attributes_register_page;
    },
    questions() {
      return Questions;
    },
    guest: {
      get() {
        return this.value;
      },
      set(data) {
        this.$emit("input", data);
      },
    },
  },
  methods: {
    answer(attribute, value) {
      this.guest[attribute] = value;
      this.questionIndex++;
      if (this.questions.length == this.questionIndex) {
        this.guest.answered_at = dayjs().format("YYYY-MM-DD HH:mm:ss");
        this.$emit("complete");
      }
    },
  },
};
</script>
