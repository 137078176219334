export default [
  {
    id: 1,
    label_ja: '日本',
    label_en: 'Japan',
    alpha: 'JPN',
    region: '東アジア',
    region_code: 'W01',
  },
  {
    id: 2,
    label_ja: '中国',
    label_en: 'China',
    alpha: 'CHN',
    region: '東アジア',
    region_code: 'W01',
  },
  {
    id: 3,
    label_ja: '韓国',
    label_en: 'Korea, Republic of',
    alpha: 'KOR',
    region: '東アジア',
    region_code: 'W01',
  },
  {
    id: 4,
    label_ja: '台湾',
    label_en: 'Taiwan, Province of China',
    alpha: 'TWN',
    region: '東アジア',
    region_code: 'W01',
  },
  {
    id: 5,
    label_ja: '香港',
    label_en: 'Hong Kong',
    alpha: 'HKG',
    region: '東アジア',
    region_code: 'W01',
  },
  {
    id: 6,
    label_ja: 'マカオ',
    label_en: 'Macau',
    alpha: 'MAC',
    region: '東アジア',
    region_code: 'W01',
  },
  {
    id: 7,
    label_ja: '朝鮮民主主義人民共和国',
    label_en: "Korea, Democratic People's Republic of",
    alpha: 'PRK',
    region: '東アジア',
    region_code: 'W01',
  },
  {
    id: 8,
    label_ja: 'モンゴル',
    label_en: 'Mongolia',
    alpha: 'MNG',
    region: '東アジア',
    region_code: 'W01',
  },
  {
    id: 9,
    label_ja: 'インドネシア',
    label_en: 'Indonesia',
    alpha: 'IDN',
    region: '東南アジア',
    region_code: 'W02',
  },
  {
    id: 10,
    label_ja: 'カンボジア',
    label_en: 'Cambodia',
    alpha: 'KHM',
    region: '東南アジア',
    region_code: 'W02',
  },
  {
    id: 11,
    label_ja: 'シンガポール',
    label_en: 'Singapore',
    alpha: 'SGP',
    region: '東南アジア',
    region_code: 'W02',
  },
  {
    id: 12,
    label_ja: 'タイ',
    label_en: 'Thailand',
    alpha: 'THA',
    region: '東南アジア',
    region_code: 'W02',
  },
  {
    id: 13,
    label_ja: '東ティモール',
    label_en: 'Timor-Leste',
    alpha: 'TLS',
    region: '東南アジア',
    region_code: 'W02',
  },
  {
    id: 14,
    label_ja: 'フィリピン',
    label_en: 'Philippines',
    alpha: 'PHL',
    region: '東南アジア',
    region_code: 'W02',
  },
  {
    id: 15,
    label_ja: 'ブルネイ・ダルサラーム',
    label_en: 'Brunei Darussalam',
    alpha: 'BRN',
    region: '東南アジア',
    region_code: 'W02',
  },
  {
    id: 16,
    label_ja: 'ベトナム',
    label_en: 'Viet Nam',
    alpha: 'VNM',
    region: '東南アジア',
    region_code: 'W02',
  },
  {
    id: 17,
    label_ja: 'マレーシア',
    label_en: 'Malaysia',
    alpha: 'MYS',
    region: '東南アジア',
    region_code: 'W02',
  },
  {
    id: 18,
    label_ja: 'ミャンマー',
    label_en: 'Myanmar',
    alpha: 'MMR',
    region: '東南アジア',
    region_code: 'W02',
  },
  {
    id: 19,
    label_ja: 'ラオス人民民主共和国',
    label_en: "Lao People's Democratic Republic",
    alpha: 'LAO',
    region: '東南アジア',
    region_code: 'W02',
  },
  {
    id: 20,
    label_ja: 'モルディブ',
    label_en: 'Maldives',
    alpha: 'MDV',
    region: '南アジア',
    region_code: 'W03',
  },
  {
    id: 21,
    label_ja: 'インド',
    label_en: 'India',
    alpha: 'IND',
    region: '南アジア',
    region_code: 'W03',
  },
  {
    id: 22,
    label_ja: 'スリランカ',
    label_en: 'Sri Lanka',
    alpha: 'LKA',
    region: '南アジア',
    region_code: 'W03',
  },
  {
    id: 23,
    label_ja: 'ネパール',
    label_en: 'Nepal',
    alpha: 'NPL',
    region: '南アジア',
    region_code: 'W03',
  },
  {
    id: 24,
    label_ja: 'パキスタン',
    label_en: 'Pakistan',
    alpha: 'PAK',
    region: '南アジア',
    region_code: 'W03',
  },
  {
    id: 25,
    label_ja: 'バングラデシュ',
    label_en: 'Bangladesh',
    alpha: 'BGD',
    region: '南アジア',
    region_code: 'W03',
  },
  {
    id: 26,
    label_ja: 'ブータン',
    label_en: 'Bhutan',
    alpha: 'BTN',
    region: '南アジア',
    region_code: 'W03',
  },
  {
    id: 27,
    label_ja: 'ウズベキスタン',
    label_en: 'Uzbekistan',
    alpha: 'UZB',
    region: '中央アジア',
    region_code: 'W04',
  },
  {
    id: 28,
    label_ja: 'カザフスタン',
    label_en: 'Kazakhstan',
    alpha: 'KAZ',
    region: '中央アジア',
    region_code: 'W04',
  },
  {
    id: 29,
    label_ja: 'キルギス',
    label_en: 'Kyrgyzstan',
    alpha: 'KGZ',
    region: '中央アジア',
    region_code: 'W04',
  },
  {
    id: 30,
    label_ja: 'タジキスタン',
    label_en: 'Tajikistan',
    alpha: 'TJK',
    region: '中央アジア',
    region_code: 'W04',
  },
  {
    id: 31,
    label_ja: 'トルクメニスタン',
    label_en: 'Turkmenistan',
    alpha: 'TKM',
    region: '中央アジア',
    region_code: 'W04',
  },
  {
    id: 32,
    label_ja: 'アフガニスタン',
    label_en: 'Afghanistan',
    alpha: 'AFG',
    region: '中東',
    region_code: 'W05',
  },
  {
    id: 33,
    label_ja: 'アラブ首長国連邦',
    label_en: 'United Arab Emirates',
    alpha: 'ARE',
    region: '中東',
    region_code: 'W05',
  },
  {
    id: 34,
    label_ja: 'イエメン',
    label_en: 'Yemen',
    alpha: 'YEM',
    region: '中東',
    region_code: 'W05',
  },
  {
    id: 35,
    label_ja: 'イスラエル',
    label_en: 'Israel',
    alpha: 'ISR',
    region: '中東',
    region_code: 'W05',
  },
  {
    id: 36,
    label_ja: 'イラク',
    label_en: 'Iraq',
    alpha: 'IRQ',
    region: '中東',
    region_code: 'W05',
  },
  {
    id: 37,
    label_ja: 'イラン・イスラム共和国',
    label_en: 'Iran (Islamic Republic of)',
    alpha: 'IRN',
    region: '中東',
    region_code: 'W05',
  },
  {
    id: 38,
    label_ja: 'オマーン',
    label_en: 'Oman',
    alpha: 'OMN',
    region: '中東',
    region_code: 'W05',
  },
  {
    id: 39,
    label_ja: 'カタール',
    label_en: 'Qatar',
    alpha: 'QAT',
    region: '中東',
    region_code: 'W05',
  },
  {
    id: 40,
    label_ja: 'クウェート',
    label_en: 'Kuwait',
    alpha: 'KWT',
    region: '中東',
    region_code: 'W05',
  },
  {
    id: 41,
    label_ja: 'サウジアラビア',
    label_en: 'Saudi Arabia',
    alpha: 'SAU',
    region: '中東',
    region_code: 'W05',
  },
  {
    id: 42,
    label_ja: 'シリア・アラブ共和国',
    label_en: 'Syrian Arab Republic',
    alpha: 'SYR',
    region: '中東',
    region_code: 'W05',
  },
  {
    id: 43,
    label_ja: 'トルコ',
    label_en: 'Turkey',
    alpha: 'TUR',
    region: '中東',
    region_code: 'W05',
  },
  {
    id: 44,
    label_ja: 'バーレーン',
    label_en: 'Bahrain',
    alpha: 'BHR',
    region: '中東',
    region_code: 'W05',
  },
  {
    id: 45,
    label_ja: 'パレスチナ',
    label_en: 'Palestine, State of',
    alpha: 'PSE',
    region: '中東',
    region_code: 'W05',
  },
  {
    id: 46,
    label_ja: 'ヨルダン',
    label_en: 'Jordan',
    alpha: 'JOR',
    region: '中東',
    region_code: 'W05',
  },
  {
    id: 47,
    label_ja: 'レバノン',
    label_en: 'Lebanon',
    alpha: 'LBN',
    region: '中東',
    region_code: 'W05',
  },
  {
    id: 48,
    label_ja: 'アメリカ領サモア',
    label_en: 'American Samoa',
    alpha: 'ASM',
    region: 'オセアニア',
    region_code: 'W06',
  },
  {
    id: 49,
    label_ja: 'ウォリス・フツナ',
    label_en: 'Wallis and Futuna',
    alpha: 'WLF',
    region: 'オセアニア',
    region_code: 'W06',
  },
  {
    id: 50,
    label_ja: 'オーストラリア',
    label_en: 'Australia',
    alpha: 'AUS',
    region: 'オセアニア',
    region_code: 'W06',
  },
  {
    id: 51,
    label_ja: '合衆国領有小離島',
    label_en: 'United States Minor Outlying Islands',
    alpha: 'UMI',
    region: 'オセアニア',
    region_code: 'W06',
  },
  {
    id: 52,
    label_ja: '北マリアナ諸島',
    label_en: 'Northern Mariana Islands',
    alpha: 'MNP',
    region: 'オセアニア',
    region_code: 'W06',
  },
  {
    id: 53,
    label_ja: 'キリバス',
    label_en: 'Kiribati',
    alpha: 'KIR',
    region: 'オセアニア',
    region_code: 'W06',
  },
  {
    id: 54,
    label_ja: 'グアム',
    label_en: 'Guam',
    alpha: 'GUM',
    region: 'オセアニア',
    region_code: 'W06',
  },
  {
    id: 55,
    label_ja: 'クック諸島',
    label_en: 'Cook Islands',
    alpha: 'COK',
    region: 'オセアニア',
    region_code: 'W06',
  },
  {
    id: 56,
    label_ja: 'クリスマス島',
    label_en: 'Christmas Island',
    alpha: 'CXR',
    region: 'オセアニア',
    region_code: 'W06',
  },
  {
    id: 57,
    label_ja: 'サモア',
    label_en: 'Samoa',
    alpha: 'WSM',
    region: 'オセアニア',
    region_code: 'W06',
  },
  {
    id: 58,
    label_ja: 'ソロモン諸島',
    label_en: 'Solomon Islands',
    alpha: 'SLB',
    region: 'オセアニア',
    region_code: 'W06',
  },
  {
    id: 59,
    label_ja: 'ツバル',
    label_en: 'Tuvalu',
    alpha: 'TUV',
    region: 'オセアニア',
    region_code: 'W06',
  },
  {
    id: 60,
    label_ja: 'トケラウ',
    label_en: 'Tokelau',
    alpha: 'TKL',
    region: 'オセアニア',
    region_code: 'W06',
  },
  {
    id: 61,
    label_ja: 'トンガ',
    label_en: 'Tonga',
    alpha: 'TON',
    region: 'オセアニア',
    region_code: 'W06',
  },
  {
    id: 62,
    label_ja: 'ナウル',
    label_en: 'Nauru',
    alpha: 'NRU',
    region: 'オセアニア',
    region_code: 'W06',
  },
  {
    id: 63,
    label_ja: 'ニウエ',
    label_en: 'Niue',
    alpha: 'NIU',
    region: 'オセアニア',
    region_code: 'W06',
  },
  {
    id: 64,
    label_ja: 'ニューカレドニア',
    label_en: 'New Caledonia',
    alpha: 'NCL',
    region: 'オセアニア',
    region_code: 'W06',
  },
  {
    id: 65,
    label_ja: 'ニュージーランド',
    label_en: 'New Zealand',
    alpha: 'NZL',
    region: 'オセアニア',
    region_code: 'W06',
  },
  {
    id: 66,
    label_ja: 'ノーフォーク島',
    label_en: 'Norfolk Island',
    alpha: 'NFK',
    region: 'オセアニア',
    region_code: 'W06',
  },
  {
    id: 67,
    label_ja: 'バヌアツ',
    label_en: 'Vanuatu',
    alpha: 'VUT',
    region: 'オセアニア',
    region_code: 'W06',
  },
  {
    id: 68,
    label_ja: 'パプアニューギニア',
    label_en: 'Papua New Guinea',
    alpha: 'PNG',
    region: 'オセアニア',
    region_code: 'W06',
  },
  {
    id: 69,
    label_ja: 'パラオ',
    label_en: 'Palau',
    alpha: 'PLW',
    region: 'オセアニア',
    region_code: 'W06',
  },
  {
    id: 70,
    label_ja: 'ピトケアン',
    label_en: 'Pitcairn',
    alpha: 'PCN',
    region: 'オセアニア',
    region_code: 'W06',
  },
  {
    id: 71,
    label_ja: 'フィジー',
    label_en: 'Fiji',
    alpha: 'FJI',
    region: 'オセアニア',
    region_code: 'W06',
  },
  {
    id: 72,
    label_ja: 'フランス領ポリネシア',
    label_en: 'French Polynesia',
    alpha: 'PYF',
    region: 'オセアニア',
    region_code: 'W06',
  },
  {
    id: 73,
    label_ja: 'マーシャル諸島',
    label_en: 'Marshall Islands',
    alpha: 'MHL',
    region: 'オセアニア',
    region_code: 'W06',
  },
  {
    id: 74,
    label_ja: 'ミクロネシア連邦',
    label_en: 'Micronesia (Federated States of)',
    alpha: 'FSM',
    region: 'オセアニア',
    region_code: 'W06',
  },
  {
    id: 75,
    label_ja: 'ココス（キーリング）諸島',
    label_en: 'Cocos (Keeling) Islands',
    alpha: 'CCK',
    region: 'オセアニア',
    region_code: 'W06',
  },
  {
    id: 76,
    label_ja: 'ハード島とマクドナルド諸島',
    label_en: 'Heard Island and McDonald Islands',
    alpha: 'HMD',
    region: 'オセアニア',
    region_code: 'W06',
  },
  {
    id: 77,
    label_ja: 'ロシア連邦',
    label_en: 'Russian Federation',
    alpha: 'RUS',
    region: 'ヨーロッパ',
    region_code: 'W07',
  },
  {
    id: 78,
    label_ja: 'アイルランド',
    label_en: 'Ireland',
    alpha: 'IRL',
    region: 'ヨーロッパ',
    region_code: 'W07',
  },
  {
    id: 79,
    label_ja: 'アンドラ',
    label_en: 'Andorra',
    alpha: 'AND',
    region: 'ヨーロッパ',
    region_code: 'W07',
  },
  {
    id: 80,
    label_ja: 'イギリス',
    label_en: 'United Kingdom of Great Britain and Northern Ireland',
    alpha: 'GBR',
    region: 'ヨーロッパ',
    region_code: 'W07',
  },
  {
    id: 81,
    label_ja: 'イタリア',
    label_en: 'Italy',
    alpha: 'ITA',
    region: 'ヨーロッパ',
    region_code: 'W07',
  },
  {
    id: 82,
    label_ja: 'オランダ',
    label_en: 'Netherlands',
    alpha: 'NLD',
    region: 'ヨーロッパ',
    region_code: 'W07',
  },
  {
    id: 83,
    label_ja: 'ガーンジー',
    label_en: 'Guernsey',
    alpha: 'GGY',
    region: 'ヨーロッパ',
    region_code: 'W07',
  },
  {
    id: 84,
    label_ja: 'ギリシャ',
    label_en: 'Greece',
    alpha: 'GRC',
    region: 'ヨーロッパ',
    region_code: 'W07',
  },
  {
    id: 85,
    label_ja: 'サンマリノ',
    label_en: 'San Marino',
    alpha: 'SMR',
    region: 'ヨーロッパ',
    region_code: 'W07',
  },
  {
    id: 86,
    label_ja: 'ジブラルタル',
    label_en: 'Gibraltar',
    alpha: 'GIB',
    region: 'ヨーロッパ',
    region_code: 'W07',
  },
  {
    id: 87,
    label_ja: 'ジャージー',
    label_en: 'Jersey',
    alpha: 'JEY',
    region: 'ヨーロッパ',
    region_code: 'W07',
  },
  {
    id: 88,
    label_ja: 'スイス',
    label_en: 'Switzerland',
    alpha: 'CHE',
    region: 'ヨーロッパ',
    region_code: 'W07',
  },
  {
    id: 89,
    label_ja: 'スペイン',
    label_en: 'Spain',
    alpha: 'ESP',
    region: 'ヨーロッパ',
    region_code: 'W07',
  },
  {
    id: 90,
    label_ja: 'ドイツ',
    label_en: 'Germany',
    alpha: 'DEU',
    region: 'ヨーロッパ',
    region_code: 'W07',
  },
  {
    id: 91,
    label_ja: 'バチカン市国',
    label_en: 'Holy See',
    alpha: 'VAT',
    region: 'ヨーロッパ',
    region_code: 'W07',
  },
  {
    id: 92,
    label_ja: 'フランス',
    label_en: 'France',
    alpha: 'FRA',
    region: 'ヨーロッパ',
    region_code: 'W07',
  },
  {
    id: 93,
    label_ja: 'ベルギー',
    label_en: 'Belgium',
    alpha: 'BEL',
    region: 'ヨーロッパ',
    region_code: 'W07',
  },
  {
    id: 94,
    label_ja: 'ポルトガル',
    label_en: 'Portugal',
    alpha: 'PRT',
    region: 'ヨーロッパ',
    region_code: 'W07',
  },
  {
    id: 95,
    label_ja: 'マン島',
    label_en: 'Isle of Man',
    alpha: 'IMN',
    region: 'ヨーロッパ',
    region_code: 'W07',
  },
  {
    id: 96,
    label_ja: 'モナコ',
    label_en: 'Monaco',
    alpha: 'MCO',
    region: 'ヨーロッパ',
    region_code: 'W07',
  },
  {
    id: 97,
    label_ja: 'リヒテンシュタイン',
    label_en: 'Liechtenstein',
    alpha: 'LIE',
    region: 'ヨーロッパ',
    region_code: 'W07',
  },
  {
    id: 98,
    label_ja: 'ルクセンブルク',
    label_en: 'Luxembourg',
    alpha: 'LUX',
    region: 'ヨーロッパ',
    region_code: 'W07',
  },
  {
    id: 99,
    label_ja: 'キプロス',
    label_en: 'Cyprus',
    alpha: 'CYP',
    region: 'ヨーロッパ',
    region_code: 'W07',
  },
  {
    id: 100,
    label_ja: 'マルタ',
    label_en: 'Malta',
    alpha: 'MLT',
    region: 'ヨーロッパ',
    region_code: 'W07',
  },
  {
    id: 101,
    label_ja: 'アゼルバイジャン',
    label_en: 'Azerbaijan',
    alpha: 'AZE',
    region: 'ヨーロッパ',
    region_code: 'W07',
  },
  {
    id: 102,
    label_ja: 'アルバニア',
    label_en: 'Albania',
    alpha: 'ALB',
    region: 'ヨーロッパ',
    region_code: 'W07',
  },
  {
    id: 103,
    label_ja: 'アルメニア',
    label_en: 'Armenia',
    alpha: 'ARM',
    region: 'ヨーロッパ',
    region_code: 'W07',
  },
  {
    id: 104,
    label_ja: 'ウクライナ',
    label_en: 'Ukraine',
    alpha: 'UKR',
    region: 'ヨーロッパ',
    region_code: 'W07',
  },
  {
    id: 105,
    label_ja: 'エストニア',
    label_en: 'Estonia',
    alpha: 'EST',
    region: 'ヨーロッパ',
    region_code: 'W07',
  },
  {
    id: 106,
    label_ja: 'オーストリア',
    label_en: 'Austria',
    alpha: 'AUT',
    region: 'ヨーロッパ',
    region_code: 'W07',
  },
  {
    id: 107,
    label_ja: '北マケドニア',
    label_en: 'North Macedonia',
    alpha: 'MKD',
    region: 'ヨーロッパ',
    region_code: 'W07',
  },
  {
    id: 108,
    label_ja: 'クロアチア',
    label_en: 'Croatia',
    alpha: 'HRV',
    region: 'ヨーロッパ',
    region_code: 'W07',
  },
  {
    id: 109,
    label_ja: 'ジョージア',
    label_en: 'Georgia',
    alpha: 'GEO',
    region: 'ヨーロッパ',
    region_code: 'W07',
  },
  {
    id: 110,
    label_ja: 'スロバキア',
    label_en: 'Slovakia',
    alpha: 'SVK',
    region: 'ヨーロッパ',
    region_code: 'W07',
  },
  {
    id: 111,
    label_ja: 'スロベニア',
    label_en: 'Slovenia',
    alpha: 'SVN',
    region: 'ヨーロッパ',
    region_code: 'W07',
  },
  {
    id: 112,
    label_ja: 'セルビア',
    label_en: 'Serbia',
    alpha: 'SRB',
    region: 'ヨーロッパ',
    region_code: 'W07',
  },
  {
    id: 113,
    label_ja: 'チェコ',
    label_en: 'Czechia',
    alpha: 'CZE',
    region: 'ヨーロッパ',
    region_code: 'W07',
  },
  {
    id: 114,
    label_ja: 'ハンガリー',
    label_en: 'Hungary',
    alpha: 'HUN',
    region: 'ヨーロッパ',
    region_code: 'W07',
  },
  {
    id: 115,
    label_ja: 'ブルガリア',
    label_en: 'Bulgaria',
    alpha: 'BGR',
    region: 'ヨーロッパ',
    region_code: 'W07',
  },
  {
    id: 116,
    label_ja: 'ベラルーシ',
    label_en: 'Belarus',
    alpha: 'BLR',
    region: 'ヨーロッパ',
    region_code: 'W07',
  },
  {
    id: 117,
    label_ja: 'ポーランド',
    label_en: 'Poland',
    alpha: 'POL',
    region: 'ヨーロッパ',
    region_code: 'W07',
  },
  {
    id: 118,
    label_ja: 'ボスニア・ヘルツェゴビナ',
    label_en: 'Bosnia and Herzegovina',
    alpha: 'BIH',
    region: 'ヨーロッパ',
    region_code: 'W07',
  },
  {
    id: 119,
    label_ja: 'モルドバ共和国',
    label_en: 'Moldova, Republic of',
    alpha: 'MDA',
    region: 'ヨーロッパ',
    region_code: 'W07',
  },
  {
    id: 120,
    label_ja: 'モンテネグロ',
    label_en: 'Montenegro',
    alpha: 'MNE',
    region: 'ヨーロッパ',
    region_code: 'W07',
  },
  {
    id: 121,
    label_ja: 'ラトビア',
    label_en: 'Latvia',
    alpha: 'LVA',
    region: 'ヨーロッパ',
    region_code: 'W07',
  },
  {
    id: 122,
    label_ja: 'リトアニア',
    label_en: 'Lithuania',
    alpha: 'LTU',
    region: 'ヨーロッパ',
    region_code: 'W07',
  },
  {
    id: 123,
    label_ja: 'ルーマニア',
    label_en: 'Romania',
    alpha: 'ROU',
    region: 'ヨーロッパ',
    region_code: 'W07',
  },
  {
    id: 124,
    label_ja: 'アイスランド',
    label_en: 'Iceland',
    alpha: 'ISL',
    region: 'ヨーロッパ',
    region_code: 'W07',
  },
  {
    id: 125,
    label_ja: 'オーランド諸島',
    label_en: 'Åland Islands',
    alpha: 'ALA',
    region: 'ヨーロッパ',
    region_code: 'W07',
  },
  {
    id: 126,
    label_ja: 'グリーンランド',
    label_en: 'Greenland',
    alpha: 'GRL',
    region: 'ヨーロッパ',
    region_code: 'W07',
  },
  {
    id: 127,
    label_ja: 'スウェーデン',
    label_en: 'Sweden',
    alpha: 'SWE',
    region: 'ヨーロッパ',
    region_code: 'W07',
  },
  {
    id: 128,
    label_ja: 'スヴァールバル諸島およびヤンマイエン島',
    label_en: 'Svalbard and Jan Mayen',
    alpha: 'SJM',
    region: 'ヨーロッパ',
    region_code: 'W07',
  },
  {
    id: 129,
    label_ja: 'デンマーク',
    label_en: 'Denmark',
    alpha: 'DNK',
    region: 'ヨーロッパ',
    region_code: 'W07',
  },
  {
    id: 130,
    label_ja: 'ノルウェー',
    label_en: 'Norway',
    alpha: 'NOR',
    region: 'ヨーロッパ',
    region_code: 'W07',
  },
  {
    id: 131,
    label_ja: 'フィンランド',
    label_en: 'Finland',
    alpha: 'FIN',
    region: 'ヨーロッパ',
    region_code: 'W07',
  },
  {
    id: 132,
    label_ja: 'フェロー諸島',
    label_en: 'Faroe Islands',
    alpha: 'FRO',
    region: 'ヨーロッパ',
    region_code: 'W07',
  },
  {
    id: 133,
    label_ja: 'セーシェル',
    label_en: 'Seychelles',
    alpha: 'SYC',
    region: 'アフリカ',
    region_code: 'W08',
  },
  {
    id: 134,
    label_ja: 'マダガスカル',
    label_en: 'Madagascar',
    alpha: 'MDG',
    region: 'アフリカ',
    region_code: 'W08',
  },
  {
    id: 135,
    label_ja: 'レユニオン',
    label_en: 'Réunion',
    alpha: 'REU',
    region: 'アフリカ',
    region_code: 'W08',
  },
  {
    id: 136,
    label_ja: 'ガーナ',
    label_en: 'Ghana',
    alpha: 'GHA',
    region: 'アフリカ',
    region_code: 'W08',
  },
  {
    id: 137,
    label_ja: 'カーボベルデ',
    label_en: 'Cabo Verde',
    alpha: 'CPV',
    region: 'アフリカ',
    region_code: 'W08',
  },
  {
    id: 138,
    label_ja: 'ガンビア',
    label_en: 'Gambia',
    alpha: 'GMB',
    region: 'アフリカ',
    region_code: 'W08',
  },
  {
    id: 139,
    label_ja: 'ギニア',
    label_en: 'Guinea',
    alpha: 'GIN',
    region: 'アフリカ',
    region_code: 'W08',
  },
  {
    id: 140,
    label_ja: 'ギニアビサウ',
    label_en: 'Guinea-Bissau',
    alpha: 'GNB',
    region: 'アフリカ',
    region_code: 'W08',
  },
  {
    id: 141,
    label_ja: 'コートジボワール',
    label_en: "Côte d'Ivoire",
    alpha: 'CIV',
    region: 'アフリカ',
    region_code: 'W08',
  },
  {
    id: 142,
    label_ja: 'シエラレオネ',
    label_en: 'Sierra Leone',
    alpha: 'SLE',
    region: 'アフリカ',
    region_code: 'W08',
  },
  {
    id: 143,
    label_ja: 'セネガル',
    label_en: 'Senegal',
    alpha: 'SEN',
    region: 'アフリカ',
    region_code: 'W08',
  },
  {
    id: 144,
    label_ja: 'セントヘレナ・アセンションおよびトリスタンダクーニャ',
    label_en: 'Saint Helena, Ascension and Tristan da Cunha',
    alpha: 'SHN',
    region: 'アフリカ',
    region_code: 'W08',
  },
  {
    id: 145,
    label_ja: 'トーゴ',
    label_en: 'Togo',
    alpha: 'TGO',
    region: 'アフリカ',
    region_code: 'W08',
  },
  {
    id: 146,
    label_ja: '西サハラ',
    label_en: 'Western Sahara',
    alpha: 'ESH',
    region: 'アフリカ',
    region_code: 'W08',
  },
  {
    id: 147,
    label_ja: 'ブルキナファソ',
    label_en: 'Burkina Faso',
    alpha: 'BFA',
    region: 'アフリカ',
    region_code: 'W08',
  },
  {
    id: 148,
    label_ja: 'ベナン',
    label_en: 'Benin',
    alpha: 'BEN',
    region: 'アフリカ',
    region_code: 'W08',
  },
  {
    id: 149,
    label_ja: 'マリ',
    label_en: 'Mali',
    alpha: 'MLI',
    region: 'アフリカ',
    region_code: 'W08',
  },
  {
    id: 150,
    label_ja: 'モーリタニア',
    label_en: 'Mauritania',
    alpha: 'MRT',
    region: 'アフリカ',
    region_code: 'W08',
  },
  {
    id: 151,
    label_ja: 'リベリア',
    label_en: 'Liberia',
    alpha: 'LBR',
    region: 'アフリカ',
    region_code: 'W08',
  },
  {
    id: 152,
    label_ja: 'ウガンダ',
    label_en: 'Uganda',
    alpha: 'UGA',
    region: 'アフリカ',
    region_code: 'W08',
  },
  {
    id: 153,
    label_ja: 'ガボン',
    label_en: 'Gabon',
    alpha: 'GAB',
    region: 'アフリカ',
    region_code: 'W08',
  },
  {
    id: 154,
    label_ja: 'カメルーン',
    label_en: 'Cameroon',
    alpha: 'CMR',
    region: 'アフリカ',
    region_code: 'W08',
  },
  {
    id: 155,
    label_ja: 'コンゴ共和国',
    label_en: 'Congo',
    alpha: 'COG',
    region: 'アフリカ',
    region_code: 'W08',
  },
  {
    id: 156,
    label_ja: 'コンゴ民主共和国',
    label_en: 'Congo, Democratic Republic of the',
    alpha: 'COD',
    region: 'アフリカ',
    region_code: 'W08',
  },
  {
    id: 157,
    label_ja: 'サントメ・プリンシペ',
    label_en: 'Sao Tome and Principe',
    alpha: 'STP',
    region: 'アフリカ',
    region_code: 'W08',
  },
  {
    id: 158,
    label_ja: '赤道ギニア',
    label_en: 'Equatorial Guinea',
    alpha: 'GNQ',
    region: 'アフリカ',
    region_code: 'W08',
  },
  {
    id: 159,
    label_ja: 'チャド',
    label_en: 'Chad',
    alpha: 'TCD',
    region: 'アフリカ',
    region_code: 'W08',
  },
  {
    id: 160,
    label_ja: '中央アフリカ共和国',
    label_en: 'Central African Republic',
    alpha: 'CAF',
    region: 'アフリカ',
    region_code: 'W08',
  },
  {
    id: 161,
    label_ja: 'ナイジェリア',
    label_en: 'Nigeria',
    alpha: 'NGA',
    region: 'アフリカ',
    region_code: 'W08',
  },
  {
    id: 162,
    label_ja: 'ニジェール',
    label_en: 'Niger',
    alpha: 'NER',
    region: 'アフリカ',
    region_code: 'W08',
  },
  {
    id: 163,
    label_ja: 'ブルンジ',
    label_en: 'Burundi',
    alpha: 'BDI',
    region: 'アフリカ',
    region_code: 'W08',
  },
  {
    id: 164,
    label_ja: 'ルワンダ',
    label_en: 'Rwanda',
    alpha: 'RWA',
    region: 'アフリカ',
    region_code: 'W08',
  },
  {
    id: 165,
    label_ja: 'エチオピア',
    label_en: 'Ethiopia',
    alpha: 'ETH',
    region: 'アフリカ',
    region_code: 'W08',
  },
  {
    id: 166,
    label_ja: 'エリトリア',
    label_en: 'Eritrea',
    alpha: 'ERI',
    region: 'アフリカ',
    region_code: 'W08',
  },
  {
    id: 167,
    label_ja: 'ケニア',
    label_en: 'Kenya',
    alpha: 'KEN',
    region: 'アフリカ',
    region_code: 'W08',
  },
  {
    id: 168,
    label_ja: 'ジブチ',
    label_en: 'Djibouti',
    alpha: 'DJI',
    region: 'アフリカ',
    region_code: 'W08',
  },
  {
    id: 169,
    label_ja: 'スーダン',
    label_en: 'Sudan',
    alpha: 'SDN',
    region: 'アフリカ',
    region_code: 'W08',
  },
  {
    id: 170,
    label_ja: 'ソマリア',
    label_en: 'Somalia',
    alpha: 'SOM',
    region: 'アフリカ',
    region_code: 'W08',
  },
  {
    id: 171,
    label_ja: 'タンザニア',
    label_en: 'Tanzania, United Republic of',
    alpha: 'TZA',
    region: 'アフリカ',
    region_code: 'W08',
  },
  {
    id: 172,
    label_ja: '南スーダン',
    label_en: 'South Sudan',
    alpha: 'SSD',
    region: 'アフリカ',
    region_code: 'W08',
  },
  {
    id: 173,
    label_ja: 'アンゴラ',
    label_en: 'Angola',
    alpha: 'AGO',
    region: 'アフリカ',
    region_code: 'W08',
  },
  {
    id: 174,
    label_ja: 'エスワティニ',
    label_en: 'Eswatini',
    alpha: 'SWZ',
    region: 'アフリカ',
    region_code: 'W08',
  },
  {
    id: 175,
    label_ja: 'ザンビア',
    label_en: 'Zambia',
    alpha: 'ZMB',
    region: 'アフリカ',
    region_code: 'W08',
  },
  {
    id: 176,
    label_ja: 'ジンバブエ',
    label_en: 'Zimbabwe',
    alpha: 'ZWE',
    region: 'アフリカ',
    region_code: 'W08',
  },
  {
    id: 177,
    label_ja: 'ナミビア',
    label_en: 'Namibia',
    alpha: 'NAM',
    region: 'アフリカ',
    region_code: 'W08',
  },
  {
    id: 178,
    label_ja: 'ボツワナ',
    label_en: 'Botswana',
    alpha: 'BWA',
    region: 'アフリカ',
    region_code: 'W08',
  },
  {
    id: 179,
    label_ja: 'マラウイ',
    label_en: 'Malawi',
    alpha: 'MWI',
    region: 'アフリカ',
    region_code: 'W08',
  },
  {
    id: 180,
    label_ja: '南アフリカ',
    label_en: 'South Africa',
    alpha: 'ZAF',
    region: 'アフリカ',
    region_code: 'W08',
  },
  {
    id: 181,
    label_ja: 'モーリシャス',
    label_en: 'Mauritius',
    alpha: 'MUS',
    region: 'アフリカ',
    region_code: 'W08',
  },
  {
    id: 182,
    label_ja: 'モザンビーク',
    label_en: 'Mozambique',
    alpha: 'MOZ',
    region: 'アフリカ',
    region_code: 'W08',
  },
  {
    id: 183,
    label_ja: 'レソト',
    label_en: 'Lesotho',
    alpha: 'LSO',
    region: 'アフリカ',
    region_code: 'W08',
  },
  {
    id: 184,
    label_ja: 'アルジェリア',
    label_en: 'Algeria',
    alpha: 'DZA',
    region: 'アフリカ',
    region_code: 'W08',
  },
  {
    id: 185,
    label_ja: 'エジプト',
    label_en: 'Egypt',
    alpha: 'EGY',
    region: 'アフリカ',
    region_code: 'W08',
  },
  {
    id: 186,
    label_ja: 'チュニジア',
    label_en: 'Tunisia',
    alpha: 'TUN',
    region: 'アフリカ',
    region_code: 'W08',
  },
  {
    id: 187,
    label_ja: 'モロッコ',
    label_en: 'Morocco',
    alpha: 'MAR',
    region: 'アフリカ',
    region_code: 'W08',
  },
  {
    id: 188,
    label_ja: 'リビア',
    label_en: 'Libya',
    alpha: 'LBY',
    region: 'アフリカ',
    region_code: 'W08',
  },
  {
    id: 189,
    label_ja: 'コモロ',
    label_en: 'Comoros',
    alpha: 'COM',
    region: 'アフリカ',
    region_code: 'W08',
  },
  {
    id: 190,
    label_ja: 'マヨット',
    label_en: 'Mayotte',
    alpha: 'MYT',
    region: 'アフリカ',
    region_code: 'W08',
  },
  {
    id: 191,
    label_ja: 'アメリカ合衆国',
    label_en: 'United States of America',
    alpha: 'USA',
    region: '北米',
    region_code: 'W09',
  },
  {
    id: 192,
    label_ja: 'カナダ',
    label_en: 'Canada',
    alpha: 'CAN',
    region: '北米',
    region_code: 'W09',
  },
  {
    id: 193,
    label_ja: 'サンピエール島・ミクロン島',
    label_en: 'Saint Pierre and Miquelon',
    alpha: 'SPM',
    region: '北米',
    region_code: 'W09',
  },
  {
    id: 194,
    label_ja: 'アメリカ領ヴァージン諸島',
    label_en: 'Virgin Islands (U.S.)',
    alpha: 'VIR',
    region: '中南米',
    region_code: 'W10',
  },
  {
    id: 195,
    label_ja: 'アルバ',
    label_en: 'Aruba',
    alpha: 'ABW',
    region: '中南米',
    region_code: 'W10',
  },
  {
    id: 196,
    label_ja: 'アンギラ',
    label_en: 'Anguilla',
    alpha: 'AIA',
    region: '中南米',
    region_code: 'W10',
  },
  {
    id: 197,
    label_ja: 'アンティグア・バーブーダ',
    label_en: 'Antigua and Barbuda',
    alpha: 'ATG',
    region: '中南米',
    region_code: 'W10',
  },
  {
    id: 198,
    label_ja: 'イギリス領ヴァージン諸島',
    label_en: 'Virgin Islands (British)',
    alpha: 'VGB',
    region: '中南米',
    region_code: 'W10',
  },
  {
    id: 199,
    label_ja: 'エルサルバドル',
    label_en: 'El Salvador',
    alpha: 'SLV',
    region: '中南米',
    region_code: 'W10',
  },
  {
    id: 200,
    label_ja: 'キューバ',
    label_en: 'Cuba',
    alpha: 'CUB',
    region: '中南米',
    region_code: 'W10',
  },
  {
    id: 201,
    label_ja: 'グアテマラ',
    label_en: 'Guatemala',
    alpha: 'GTM',
    region: '中南米',
    region_code: 'W10',
  },
  {
    id: 202,
    label_ja: 'グアドループ',
    label_en: 'Guadeloupe',
    alpha: 'GLP',
    region: '中南米',
    region_code: 'W10',
  },
  {
    id: 203,
    label_ja: 'グレナダ',
    label_en: 'Grenada',
    alpha: 'GRD',
    region: '中南米',
    region_code: 'W10',
  },
  {
    id: 204,
    label_ja: 'ケイマン諸島',
    label_en: 'Cayman Islands',
    alpha: 'CYM',
    region: '中南米',
    region_code: 'W10',
  },
  {
    id: 205,
    label_ja: 'コスタリカ',
    label_en: 'Costa Rica',
    alpha: 'CRI',
    region: '中南米',
    region_code: 'W10',
  },
  {
    id: 206,
    label_ja: 'サン・バルテルミー',
    label_en: 'Saint Barthélemy',
    alpha: 'BLM',
    region: '中南米',
    region_code: 'W10',
  },
  {
    id: 207,
    label_ja: 'サン・マルタン（フランス領）',
    label_en: 'Saint Martin (French part)',
    alpha: 'MAF',
    region: '中南米',
    region_code: 'W10',
  },
  {
    id: 208,
    label_ja: 'ジャマイカ',
    label_en: 'Jamaica',
    alpha: 'JAM',
    region: '中南米',
    region_code: 'W10',
  },
  {
    id: 209,
    label_ja: 'セントクリストファー・ネイビス',
    label_en: 'Saint Kitts and Nevis',
    alpha: 'KNA',
    region: '中南米',
    region_code: 'W10',
  },
  {
    id: 210,
    label_ja: 'セントビンセントおよびグレナディーン諸島',
    label_en: 'Saint Vincent and the Grenadines',
    alpha: 'VCT',
    region: '中南米',
    region_code: 'W10',
  },
  {
    id: 211,
    label_ja: 'セントルシア',
    label_en: 'Saint Lucia',
    alpha: 'LCA',
    region: '中南米',
    region_code: 'W10',
  },
  {
    id: 212,
    label_ja: 'タークス・カイコス諸島',
    label_en: 'Turks and Caicos Islands',
    alpha: 'TCA',
    region: '中南米',
    region_code: 'W10',
  },
  {
    id: 213,
    label_ja: 'ドミニカ共和国',
    label_en: 'Dominican Republic',
    alpha: 'DOM',
    region: '中南米',
    region_code: 'W10',
  },
  {
    id: 214,
    label_ja: 'ドミニカ国',
    label_en: 'Dominica',
    alpha: 'DMA',
    region: '中南米',
    region_code: 'W10',
  },
  {
    id: 215,
    label_ja: 'トリニダード・トバゴ',
    label_en: 'Trinidad and Tobago',
    alpha: 'TTO',
    region: '中南米',
    region_code: 'W10',
  },
  {
    id: 216,
    label_ja: 'ニカラグア',
    label_en: 'Nicaragua',
    alpha: 'NIC',
    region: '中南米',
    region_code: 'W10',
  },
  {
    id: 217,
    label_ja: 'ハイチ',
    label_en: 'Haiti',
    alpha: 'HTI',
    region: '中南米',
    region_code: 'W10',
  },
  {
    id: 218,
    label_ja: 'パナマ',
    label_en: 'Panama',
    alpha: 'PAN',
    region: '中南米',
    region_code: 'W10',
  },
  {
    id: 219,
    label_ja: 'バハマ',
    label_en: 'Bahamas',
    alpha: 'BHS',
    region: '中南米',
    region_code: 'W10',
  },
  {
    id: 220,
    label_ja: 'バミューダ',
    label_en: 'Bermuda',
    alpha: 'BMU',
    region: '中南米',
    region_code: 'W10',
  },
  {
    id: 221,
    label_ja: 'バルバドス',
    label_en: 'Barbados',
    alpha: 'BRB',
    region: '中南米',
    region_code: 'W10',
  },
  {
    id: 222,
    label_ja: 'プエルトリコ',
    label_en: 'Puerto Rico',
    alpha: 'PRI',
    region: '中南米',
    region_code: 'W10',
  },
  {
    id: 223,
    label_ja: 'ベリーズ',
    label_en: 'Belize',
    alpha: 'BLZ',
    region: '中南米',
    region_code: 'W10',
  },
  {
    id: 224,
    label_ja: 'ボネール、シント・ユースタティウスおよびサバ',
    label_en: 'Bonaire, Sint Eustatius and Saba',
    alpha: 'BES',
    region: '中南米',
    region_code: 'W10',
  },
  {
    id: 225,
    label_ja: 'ホンジュラス',
    label_en: 'Honduras',
    alpha: 'HND',
    region: '中南米',
    region_code: 'W10',
  },
  {
    id: 226,
    label_ja: 'マルティニーク',
    label_en: 'Martinique',
    alpha: 'MTQ',
    region: '中南米',
    region_code: 'W10',
  },
  {
    id: 227,
    label_ja: 'メキシコ',
    label_en: 'Mexico',
    alpha: 'MEX',
    region: '中南米',
    region_code: 'W10',
  },
  {
    id: 228,
    label_ja: 'モントセラト',
    label_en: 'Montserrat',
    alpha: 'MSR',
    region: '中南米',
    region_code: 'W10',
  },
  {
    id: 229,
    label_ja: 'アルゼンチン',
    label_en: 'Argentina',
    alpha: 'ARG',
    region: '中南米',
    region_code: 'W10',
  },
  {
    id: 230,
    label_ja: 'ウルグアイ',
    label_en: 'Uruguay',
    alpha: 'URY',
    region: '中南米',
    region_code: 'W10',
  },
  {
    id: 231,
    label_ja: 'エクアドル',
    label_en: 'Ecuador',
    alpha: 'ECU',
    region: '中南米',
    region_code: 'W10',
  },
  {
    id: 232,
    label_ja: 'ガイアナ',
    label_en: 'Guyana',
    alpha: 'GUY',
    region: '中南米',
    region_code: 'W10',
  },
  {
    id: 233,
    label_ja: 'コロンビア',
    label_en: 'Colombia',
    alpha: 'COL',
    region: '中南米',
    region_code: 'W10',
  },
  {
    id: 234,
    label_ja: 'サウスジョージア・サウスサンドウィッチ諸島',
    label_en: 'South Georgia and the South Sandwich Islands',
    alpha: 'SGS',
    region: '中南米',
    region_code: 'W10',
  },
  {
    id: 235,
    label_ja: 'スリナム',
    label_en: 'Surija',
    alpha: 'SUR',
    region: '中南米',
    region_code: 'W10',
  },
  {
    id: 236,
    label_ja: 'チリ',
    label_en: 'Chile',
    alpha: 'CHL',
    region: '中南米',
    region_code: 'W10',
  },
  {
    id: 237,
    label_ja: 'パラグアイ',
    label_en: 'Paraguay',
    alpha: 'PRY',
    region: '中南米',
    region_code: 'W10',
  },
  {
    id: 238,
    label_ja: 'フォークランド（マルビナス）諸島',
    label_en: 'Falkland Islands (Malvinas)',
    alpha: 'FLK',
    region: '中南米',
    region_code: 'W10',
  },
  {
    id: 239,
    label_ja: 'ブラジル',
    label_en: 'Brazil',
    alpha: 'BRA',
    region: '中南米',
    region_code: 'W10',
  },
  {
    id: 240,
    label_ja: 'フランス領ギアナ',
    label_en: 'French Guiana',
    alpha: 'GUF',
    region: '中南米',
    region_code: 'W10',
  },
  {
    id: 241,
    label_ja: 'ベネズエラ・ボリバル共和国',
    label_en: 'Venezuela (Bolivarian Republic of)',
    alpha: 'VEN',
    region: '中南米',
    region_code: 'W10',
  },
  {
    id: 242,
    label_ja: 'ペルー',
    label_en: 'Peru',
    alpha: 'PER',
    region: '中南米',
    region_code: 'W10',
  },
  {
    id: 243,
    label_ja: 'ボリビア多民族国',
    label_en: 'Bolivia (Plurinational State of)',
    alpha: 'BOL',
    region: '中南米',
    region_code: 'W10',
  },
  {
    id: 244,
    label_ja: 'イギリス領インド洋地域',
    label_en: 'British Indian Ocean Territory',
    alpha: 'IOT',
    region: 'その他',
    region_code: 'W11',
  },
  {
    id: 245,
    label_ja: 'フランス領南方・南極地域',
    label_en: 'French Southern Territories',
    alpha: 'ATF',
    region: 'その他',
    region_code: 'W11',
  },
  {
    id: 246,
    label_ja: '南極',
    label_en: 'Antarctica',
    alpha: 'ATA',
    region: 'その他',
    region_code: 'W11',
  },
  {
    id: 247,
    label_ja: 'ブーベ島',
    label_en: 'Bouvet Island',
    alpha: 'BVT',
    region: 'その他',
    region_code: 'W11',
  },
]
