import Locales from '@/library/Lang/Lang.json'

export default {
  install(vue) {
    console.log('Installing Main plugin..')
    vue.mixin({
      data: () => ({
        dataReady: false,
      }),
      computed: {
        viewLoading: {
          get() {
            return this.$root.viewLoading_
          },
          set(val) {
            this.$root.viewLoading_ = val
          },
        },
        lang: {
          get() {
            return this.$root.lang_
          },
          set(val) {
            this.$root.lang_ = val
            document.documentElement.lang = val.split('_').join('-') //追加
            if (this.$route.query.lang !== val) {
              const query = _.cloneDeep(this.$route.query)
              query.lang = val
              this.$router.replace({ query: query })
            }
          },
        },
        bookmarks: {
          get() {
            return this.$root.bookmarks_
          },
          set(val) {
            this.$root.bookmarks_ = val
          },
        },
        bookmarkIds: {
          get() {
            return this.$root.bookmarkIds_
          },
          set(val) {
            this.$root.bookmarkIds_ = val
          },
        },
        locales() {
          return Locales
        }
      },
      methods: {
        getLabel(labelObject) {
          if (!labelObject) return null
          if (!this.lang) return labelObject.ja
          if (labelObject[this.lang]) {
            return labelObject[this.lang]
          } else if (labelObject.en) {
            return labelObject.en
          } else {
            return labelObject.ja
          }
        },
      }
    })
  }
}
