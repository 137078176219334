import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { Amplify } from 'aws-amplify'
import awsconfig from './aws-exports'
Amplify.configure(awsconfig)

import MainPlugin from '@/plugins/Main.js'
import ConstantsPlugin from '@/plugins/Constants.js'
import ValidationPlugin from '@/plugins/Validations.js'

import MainMixin from '@/mixins/Main.js'

import lodash from 'lodash'
import dayjs from 'dayjs'
window._ = lodash
window.dayjs = dayjs

// 開発用デバッグ情報 - リリース時コメントアウト！
Vue.config.productionTip = true
Vue.config.devtools = true

Vue.use(MainPlugin)
Vue.use(ConstantsPlugin)
Vue.use(ValidationPlugin)

new Vue({
  mixins: [MainMixin],
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app')
