<template>
  <v-container v-if="dataReady">
    <v-toolbar flat color="transparent">
      {{ getLabel(locales.qrLink) }}
    </v-toolbar>

    <v-card v-if="hotel.id">
      <v-card-title>
        {{ getLabel(locales.registration) }}
      </v-card-title>
      <v-divider></v-divider>
      <v-form ref="form" v-model="valid" @submit.prevent="submit">
        <v-container>
          <v-row>
            <v-col>
              {{ getLabel(locales.info) }}
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                readonly
                type="text"
                :value="hotelName"
                :label="getLabel(locales.accommodations)"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                type="text"
                v-model="guest.room_name"
                :label="getLabel(locales.roomNum)"
                :rules="[formRules.required]"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <DatePicker
                v-model="guest.checked_in_on"
                :label="getLabel(locales.checkIn)"
                :rules="[formRules.required]"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <DatePicker
                v-model="guest.checked_out_on"
                :label="getLabel(locales.checkOut)"
                :rules="[formRules.required]"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              {{ getLabel(locales.customerInfo) }}
            </v-col>
          </v-row>

          <v-row v-if="lang == 'en'">
            <v-col>
              <v-text-field
                type="text"
                name="given-name"
                v-model="guest.given_name"
                :label="getLabel(locales.givName)"
                :rules="[formRules.required]"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                type="text"
                name="family-name"
                v-model="guest.family_name"
                :label="getLabel(locales.famName)"
                :rules="[formRules.required]"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row v-else>
            <v-col>
              <v-text-field
                type="text"
                name="family-name"
                v-model="guest.family_name"
                :label="getLabel(locales.famName)"
                :rules="[formRules.required]"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                type="text"
                name="given-name"
                v-model="guest.given_name"
                :label="getLabel(locales.givName)"
                :rules="[formRules.required]"
              ></v-text-field>
            </v-col>
          </v-row>

          <template v-if="lang == 'ja'">
            <v-row>
              <v-col>
                <v-text-field
                  type="text"
                  v-model="guest.family_name_kana"
                  :label="getLabel(locales.famNameKana)"
                  :rules="[formRules.required]"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  type="text"
                  v-model="guest.given_name_kana"
                  :label="getLabel(locales.givNameKana)"
                  :rules="[formRules.required]"
                ></v-text-field>
              </v-col>
            </v-row>
          </template>

          <v-row>
            <v-col>
              <v-select
                v-model="guest.age"
                :items="getLabel(COMMON_AGES)"
                menu-props="auto"
                :label="getLabel(locales.age)"
                :rules="[formRules.required]"
              ></v-select>
            </v-col>
            <v-col>
              <v-select
                v-model="guest.sex"
                :items="getLabel(COMMON_SEX)"
                menu-props="auto"
                :label="getLabel(locales.sex)"
                :rules="[formRules.required]"
              ></v-select>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-autocomplete
                v-model="guest.residence"
                :items="countries"
                :item-text="lang == 'ja' ? 'label_ja' : 'label_en'"
                item-value="label_ja"
                autocomplete="country"
                menu-props="auto"
                :label="`${getLabel(locales.country)}${getLabel(
                  locales.searchable
                )}`"
                :rules="[formRules.required]"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-text-field
                type="email"
                name="email"
                v-model="guest.email"
                :label="getLabel(locales.confirmEmail)"
                persistent-hint
                :hint="getLabel(locales.hint)"
                :rules="[formRules.required]"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>

        <v-divider></v-divider>
        <v-card-actions class="py-6 d-flex flex-column">
          <v-spacer></v-spacer>
          <v-btn block type="submit" color="primary" :disabled="!valid">
            {{ getLabel(locales.register) }}
          </v-btn>
          <div
            v-if="!valid"
            class="red--text text-center text-subtitle-2 font-weight-medium"
          >
            {{ getLabel(locales.pleaseFillInRequired) }}
          </div>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-container>
</template>

<script>
import { models } from '@am-area/sdk'
const { Guest, Hotel } = models
import axios from 'axios'
import DatePicker from '@/components/DatePicker.vue'
import Countries from '@/library/Countries'
export default {
  components: {
    DatePicker,
  },
  data: () => ({
    valid: true,
    showCheckInModal: false,
    showCheckOutModal: false,
    hotel: {},
    guest: {
      hotel_id: null,
      room_name: null,
      email: null,
      family_name: null,
      given_name: null,
      family_name_kana: null,
      given_name_kana: null,
      checked_in_on: null,
      checked_out_on: null,
      residence: null,
      lang: 'ja',
      // attr_transportation: null,
      // attr_purpose: null,
      // attr_customer_base: null,
      // attr_travel_type: null,
      // attr_market: null,
      // attr_channel: null,
      // attr_source: null,
      // attr_from: null,
      total_amount: 0,
      // answered_at: null,
      // created_at: null,
      // updated_at: null,
      // deleted_at: null,
    },
  }),
  props: {
    guestData: Object,
  },
  watch: {
    'guest.checked_in_on'() {
      this.showCheckInModal = false
    },
    'guest.checked_out_on'() {
      this.showCheckOutModal = false
    },
  },
  async created() {
    await this.getData()
  },
  computed: {
    hotelId() {
      return this.$route.params.hotelId
    },
    countries() {
      return Countries
    },
    hotelName() {
      const lang = this.$route.query.lang
      switch (lang) {
        case 'ja':
          return this.hotel.name.ja
          break
        case 'en':
          return this.hotel.name.en
          break
        case 'ko':
          return this.hotel.name.ko
          break
        case 'zh_CN':
          return this.hotel.name.zh_CN
          break
        case 'zh_TW':
          return this.hotel.name.zh_TW
          break
        default:
          return this.hotel.name.ja
      }
    },
  },
  methods: {
    pickerDayformat(val) {
      return dayjs(val).format('D')
    },
    async setGuest() {
      const guestId = localStorage.getItem('guestId')
      try {
        if (guestId) {
          let guest = await Guest.get(guestId)
          delete guest.id
          delete guest.created_at
          delete guest.updateed_at
          const keepKeys = [
            'email',
            'lang',
            'age',
            'sex',
            'family_name',
            'given_name',
            'family_name_kana',
            'given_name_kana',
            'residence',
          ]
          // データリセット
          Object.keys(guest).forEach((key) => {
            if (keepKeys.findIndex((val) => val == key) < 0) {
              guest[key] = null
            }
          })
          guest.total_amount = 0

          this.guest = guest
        }
      } catch (e) {
        // シーディングし直した時にDBに存在しないIDで検索してしまうため
        localStorage.clear()
      }
    },
    async getData() {
      // ローカルストレージにguestIdが残っていれば一旦消去
      const guestId = localStorage.getItem('guestId')
      if (guestId) localStorage.removeItem('guestId')

      await Promise.all([this.setGuest()])
      this.hotel = await Hotel.get(this.hotelId)
      this.guest.hotel_id = this.hotelId
      //戻るボタンで戻ってきたときにデータ取得
      if (this.guestData !== undefined) {
        this.guest = await Guest.get(this.guestData.id)
        //メールアドレス変更するのでid新規作成
        this.guest.id = ''
        this.hotel = await Hotel.get(this.guestData.hotel_id)
        this.$route.query.lang = this.guest.lang
      }
      this.dataReady = true
    },
    async submit() {
      if (!this.$refs.form.validate()) return
      this.guest.lang = this.$route.query.lang
      const guest = await Guest.create(this.guest)
      switch (this.$route.query.lang) {
        case 'ja':
          if (
            confirm(
              `URLを下記メールアドレスに送信しました。\r\nメールアドレスに誤りがないかご確認ください。\r\n※メールアドレスが異なる場合は、キャンセルボタンを押して再登録を行ってください。\r\nメールアドレス: ${this.guest.email}`
            )
          ) {
            this.$router.push({
              name: 'welcome',
              params: { guest: guest },
            })
          }
          break
        case 'en':
          if (
            confirm(
              `We sent a URL to the following email address.\r\nPlease confirm if the email address is correct.\r\n*If the email address is wrong, please press the cancel button and re-register\r\nEmail address :${this.guest.email}`
            )
          ) {
            this.$router.push({
              name: 'welcome',
              params: { guest: guest },
            })
          }
          break
        case 'ko':
          if (
            confirm(
              `URL을 아래 이메일 주소로 보냈습니다.\r\n이메일 주소에 오류가 있는지 확인하십시오. \r\n※메일 주소가 다른 경우에는 취소 버튼을 눌러 다시 등록해 주세요.\r\n이메일 주소:${this.guest.email}`
            )
          ) {
            this.$router.push({
              name: 'welcome',
              params: { guest: guest },
            })
          }
          break
        case 'zh_CN':
          if (
            confirm(
              `我将 URL 发送到以下电子邮件地址.\r\n请检查电子邮件地址是否正确 \r\n 如果电子邮件地址不同，请按取消按钮并重新注册\r\n电子邮件地址:${this.guest.email}`
            )
          ) {
            this.$router.push({
              name: 'welcome',
              params: { guest: guest },
            })
          }
          break
        case 'zh_TW':
          if (
            confirm(
              `URL 已发送至以下电子邮件地址. \r\n请检查您的电子邮件地址是否正确. \r\n如果電子郵件地址不同，請按取消按鈕並重新註冊\r\n电子邮件地址：:${this.guest.email}`
            )
          ) {
            this.$router.push({
              name: 'welcome',
              params: { guest: guest },
            })
          }
          break
        default:
          if (
            confirm(
              `URLを下記メールアドレスに送信しました。\r\nメールアドレスに誤りがないかご確認ください。\r\n※メールアドレスが異なる場合は、キャンセルボタンを押して再登録を行ってください\r\nメールアドレス:${this.guest.email}`
            )
          ) {
            this.$router.push({
              name: 'welcome',
              params: { guest: guest },
            })
          }
      }
    },
  },
}
</script>
