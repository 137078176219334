var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-app-bar',{attrs:{"app":"","color":"primary","flat":"","dark":""},scopedSlots:_vm._u([(_vm.$route.name == 'tickets')?{key:"extension",fn:function(){return [_c('v-tabs',{attrs:{"dark":"","centered":"","show-arrows":"","background-color":"primary darken-1"},model:{value:(_vm.tabs),callback:function ($$v) {_vm.tabs=$$v},expression:"tabs"}},_vm._l((_vm.categories),function(cat,i){return _c('v-tab',{key:i,on:{"change":function($event){return _vm.updateCategory(cat.id)}}},[_vm._v(" "+_vm._s(_vm.getLabel(cat.name))+" ")])}),1)]},proxy:true}:null],null,true)},[_c('v-slide-x-transition',{attrs:{"mode":"out-in"}},[_c('div',[(_vm.$route.meta.backPath)?_c('v-btn',{staticClass:"text-uppercase ",attrs:{"outlined":""},on:{"click":function($event){return _vm.$router.push({ name: 'home' })}}},[_c('v-icon',[_vm._v("mdi-home")])],1):_vm._e()],1)]),_c('v-spacer'),(_vm.$route.params.guestId)?_c('v-btn',{attrs:{"outlined":"","to":{
        name: 'used-tickets',
      }}},[_c('v-icon',[_vm._v("mdi-ticket-outline")])],1):_vm._e(),(_vm.$route.params.guestId)?_c('v-btn',{staticClass:"ml-2",attrs:{"outlined":"","to":{
        name: 'favorites',
      }}},[_c('v-icon',[_vm._v("mdi-heart")])],1):_vm._e(),(_vm.area.language)?_c('div',{staticClass:"text-center"},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"outlined":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-translate")]),_vm._v(" "+_vm._s(_vm.area.language.find(function (d) { return d.value == _vm.langSelected; }).text || '日本語')+" ")],1)]}}],null,false,2309548035)},[_c('v-list',[_c('v-list-item-group',{attrs:{"color":"primary"},model:{value:(_vm.langSelected),callback:function ($$v) {_vm.langSelected=$$v},expression:"langSelected"}},_vm._l((_vm.area.language),function(la,i){return _c('v-list-item',{key:i,attrs:{"value":la.value}},[_c('v-list-item-title',[_vm._v(_vm._s(la.text || '日本語'))])],1)}),1)],1)],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }