export default [
  {
    question: {
      title: {
        ja: "アンケート",
        en: "Survey",
        ko: "설문조사",
        zh_CN: "调查",
        zh_TW: "問卷",
      },
    },
    name: {
      ja: "交通手段",
      en: "Transportation",
      ko: "교통수단",
      zh_CN: "交通手段",
      zh_TW: "運輸",
    },
    attribute: "attr_transportation",
    options: [
      {
        name: {
          ja: "自家用車",
          en: "Private Car",
          ko: "자가용차",
          zh_CN: "私家车",
          zh_TW: "私人汽車",
        },
        value: "PCA",
      },
      {
        name: {
          ja: "レンタカー",
          en: "Rental Car",
          ko: "렌터카",
          zh_CN: "租车",
          zh_TW: "租車",
        },
        value: "RCA",
      },
      {
        name: {
          ja: "バス",
          en: "Bus",
          ko: "버스",
          zh_CN: "巴士",
          zh_TW: "公共汽車",
        },
        value: "BU",
      },
      {
        name: {
          ja: "電車",
          en: "Train",
          ko: "기차",
          zh_CN: "电动火车",
          zh_TW: "電動火車",
        },
        value: "TR",
      },
      {
        name: {
          ja: "タクシー",
          en: "Cab",
          ko: "택시",
          zh_CN: "出租车",
          zh_TW: "出租車",
        },
        value: "TX",
      },
      {
        name: {
          ja: "送迎",
          en: "Pick-up",
          ko: "픽업",
          zh_CN: "迎送",
          zh_TW: "撿起",
        },
        value: "TF",
      },
      {
        name: {
          ja: "その他",
          en: "Other",
          ko: "기타",
          zh_CN: "其他",
          zh_TW: "其他",
        },
        value: "OTH",
      },
    ],
  },
  {
    name: {
      ja: "利用目的",
      en: "Purpose of Use",
      ko: "이용 목적",
      zh_CN: "使用目的",
      zh_TW: "使用目的",
    },
    attribute: "attr_purpose",
    options: [
      {
        name: {
          ja: "観光",
          en: "Sightseeing",
          ko: "관광",
          zh_CN: "观光",
          zh_TW: "觀光",
        },
        value: "ST",
      },
      {
        name: {
          ja: "レジャー",
          en: "Leisure",
          ko: "레저",
          zh_CN: "休闲",
          zh_TW: "閒暇",
        },
        value: "LS",
      },
      {
        name: {
          ja: "ビジネス",
          en: "Business",
          ko: "사업",
          zh_CN: "商业",
          zh_TW: "商業",
        },
        value: "BJ",
      },
      {
        name: {
          ja: "受験",
          en: "Examination",
          ko: "수험",
          zh_CN: "考试",
          zh_TW: "考試",
        },
        value: "TE",
      },
      {
        name: {
          ja: "冠婚葬祭",
          en: "Ceremonial Occasion",
          ko: "관혼상제",
          zh_CN: "礼仪场合",
          zh_TW: "禮儀場合",
        },
        value: "CM",
      },
      {
        name: {
          ja: "教育旅行",
          en: "Educational Trip",
          ko: "교육여행",
          zh_CN: "学校旅行",
          zh_TW: "教育之旅",
        },
        value: "ET",
      },
      {
        name: {
          ja: "学会",
          en: "Academic Society",
          ko: "학회",
          zh_CN: "学术会议",
          zh_TW: "社會",
        },
        value: "SO",
      },
      {
        name: {
          ja: "合宿",
          en: "Training Camp",
          ko: "합숙",
          zh_CN: "训练营",
          zh_TW: "訓練營",
        },
        value: "TC",
      },
      {
        name: {
          ja: "湯治",
          en: "Spa",
          ko: "유지",
          zh_CN: "温泉疗法",
          zh_TW: "溫泉療愈",
        },
        value: "HC",
      },
      {
        name: {
          ja: "その他",
          en: "Other",
          ko: "기타",
          zh_CN: "其他",
          zh_TW: "其他",
        },
        value: "OTH",
      },
    ],
  },
  {
    name: {
      ja: "客層",
      en: "Customer Base",
      ko: "고객층",
      zh_CN: "客户的质量",
      zh_TW: "客戶群",
    },
    attribute: "attr_customer_base",
    options: [
      {
        name: {
          ja: "ファミリー",
          en: "Family",
          ko: "가족",
          zh_CN: "家庭",
          zh_TW: "家庭",
        },
        value: "FA",
      },
      {
        name: {
          ja: "夫婦",
          en: "Married Couple",
          ko: "부부",
          zh_CN: "夫妻",
          zh_TW: "夫妻",
        },
        value: "OC",
      },
      {
        name: {
          ja: "カップル",
          en: "Couple",
          ko: "커플",
          zh_CN: "情侣",
          zh_TW: "情侶",
        },
        value: "CU",
      },
      {
        name: {
          ja: "一人",
          en: "Private",
          ko: "혼자",
          zh_CN: "一个人",
          zh_TW: "一個人",
        },
        value: "OP",
      },
      {
        name: {
          ja: "グループ",
          en: "Group",
          ko: "그룹",
          zh_CN: "群体",
          zh_TW: "團體",
        },
        value: "GP",
      },
      {
        name: {
          ja: "ペット",
          en: "Pet",
          ko: "반려동물",
          zh_CN: "宠物",
          zh_TW: "寵物",
        },
        value: "PT",
      },
      {
        name: {
          ja: "子連れ",
          en: "With kids",
          ko: "어린이 동반",
          zh_CN: "带着孩子",
          zh_TW: "帶著孩子",
        },
        value: "PT",
      },
      {
        name: {
          ja: "その他",
          en: "Other",
          ko: "기타",
          zh_CN: "其他",
          zh_TW: "其他",
        },
        value: "OTH",
      },
    ],
  },
  {
    name: {
      ja: "マーケット",
      en: "Market",
      ko: "마켓",
      zh_CN: "市场",
      zh_TW: "市場",
    },
    attribute: "attr_market",
    options: [
      {
        name: {
          ja: "個人",
          en: "Individual",
          ko: "개인",
          zh_CN: "个人",
          zh_TW: "個人",
        },
        value: "FIT",
      },
      {
        name: {
          ja: "団体",
          en: "Group",
          ko: "단체",
          zh_CN: "组织",
          zh_TW: "團體",
        },
        value: "GRO",
      },
      // {
      //   name: {
      //     ja: "会員",
      //     en: "Member",
      //     ko: "회원",
      //     zh_CN: "会员",
      //     zh_TW: "成員",
      //   },
      //   value: "MEB",
      // },
      // {
      //   name: {
      //     ja: "ハウスユース",
      //     en: "House-use",
      //     ko: "하우스 청소년",
      //     zh_CN: "房屋用途",
      //     zh_TW: "住宅使用",
      //   },
      //   value: "HUS",
      // },
      // {
      //   name: {
      //     ja: "コンプリメンタリー",
      //     en: "Complementary",
      //     ko: "완성",
      //     zh_CN: "补充性的",
      //     zh_TW: "补充的",
      //   },
      //   value: "COMP",
      // },
      {
        name: {
          ja: "その他",
          en: "Other",
          ko: "기타",
          zh_CN: "其他",
          zh_TW: "其他",
        },
        value: "OTH",
      },
    ],
  },
];
