import Vue from 'vue'
import VueRouter from 'vue-router'
import Welcome from '@/views/Welcome.vue'
import Register from '@/views/Register.vue'
import Guest from '@/views/Guest.vue'
import Home from '@/views/Guest/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'welcome',
    component: Welcome,
    props: true
  },
  {
    path: '/register/:hotelId',
    name: 'register',
    component: Register,
    props: true
  },
  {
    path: '/:guestId',
    name: 'guest',
    component: Guest,
    children: [
      {
        path: '',
        name: 'home',
        component: Home,
      },
      {
        path: 'tickets',
        name: 'tickets',
        component: () => import('@/views/Guest/Tickets.vue'),
        meta: {
          backPath: { name: 'home' },
        },
        children: [
          {
            path: ':ticketId',
            name: 'ticket',
            meta: {
              backPath: { name: 'tickets' },
            },
            component: () => import('@/views/Guest/Tickets/Ticket.vue'),
          },
        ],
      },
      {
        path: 'favorites',
        name: 'favorites',
        meta: {
          backPath: { name: 'tickets' },
        },
        component: () => import('@/views/Guest/Favorites.vue'),
      },
      {
        path: 'used-tickets',
        name: 'used-tickets',
        meta: {
          backPath: { name: 'tickets' },
        },
        component: () => import('@/views/Guest/UsedTickets.vue'),
      },
    ],
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
