<template>
  <div>
    <v-container>
      <!-- <v-toolbar flat color="transparent">
        {{ getLabel(locales.welcome) }}
      </v-toolbar>
      <v-card>
        <v-card-title>
          {{ getLabel(locales.setup) }}
        </v-card-title>
        <v-card-subtitle>
          {{ getLabel(locales.qrCode) }}
        </v-card-subtitle>
        <v-divider></v-divider>
        <v-container>
          <v-form @submit.prevent="submit">
            <v-container>
              <v-row>
                <v-col>
                  <v-text-field readonly type="text" :label="getLabel(locales.setupCode)"></v-text-field>
                </v-col>
              </v-row>
            </v-container>
            <v-card-actions class="py-6">
              <v-spacer></v-spacer>
              <v-btn type="submit" color="primary">
                {{ getLabel(locales.submit) }}
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-container>
      </v-card> -->
      <v-row align-content="center" style="height: 500px">
        <v-col align="center"
          >{{ getLabel(locales.pleaseAccessFromMailUrl) }}
          <div>{{ getLabel(locales.checkMailAddress) }}</div>
          <div class="mb-4"> {{ getLabel(locales.mailAddress) }}{{ email }}</div>
          <span>
            <v-btn outlined class="text-uppercase" @click="$router.push({
              name: 'register',
              params: { guestData: guest ,hotelId: guest.hotel_id,lang: guest.lang},
            })">
              <v-icon left>mdi-arrow-left</v-icon>
              {{ getLabel(locales.back) }}
            </v-btn></span
          >
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import * as SDK from '@am-area/sdk'
import { models } from '@am-area/sdk'
const { Guest, Hotel } = models
export default {
  data: () => ({
    email: '',
  }),
  props: {
    guest: Object,
  },
  async created() {
    await this.getGuest()
  },
  methods: {
    submit() {
      this.$router.replace({
        name: 'register',
        params: {
          hotelId: 'HOTEL-ID',
        },
      })
    },
    async getGuest() {
      let guest = await Guest.get(this.guest.id)
      this.email = guest.email
    },
  },
}
</script>
