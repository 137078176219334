<template>
  <v-img
    :src="src"
    :width="width"
    :height="height"
    :aspect-ratio="aspectRatio"
  ></v-img>
</template>

<script>
import { Storage } from "aws-amplify";
export default {
  created() {
    this.getImageUrl();
  },
  watch: {
    path() {
      this.getImageUrl();
    },
  },

  props: {
    path: String,
    width: String,
    height: String,
    aspectRatio: Number,
  },
  data: () => ({
    src: null,
  }),
  methods: {
    async getImageUrl() {
      if (!this.path) return;
      const oneTimeUrl = await Storage.get(this.path);
      if (oneTimeUrl) {
        this.src = oneTimeUrl;
      }
    },
  },
};
</script>
