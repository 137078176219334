<template>
  <div
    v-if="viewLoading || !dataReady"
    class="d-flex align-center justify-center"
    style="height: 90vh"
  >
    <v-progress-circular indeterminate color="primary" size="64" />
  </div>
  <div v-else>
    <router-view
      :area="area"
      :guest="guest"
      :hotel="hotel"
      :tickets="tickets"
      :ticketTokens="ticketTokens"
      :getData="getData"
      :categories="categories"
      :ticketsData="tickets"
      :suggests="suggests"
      @get-data="getData()"
    ></router-view>
    <Questions v-model="guest" :hotel="hotel" @complete="updateGuest()" />
  </div>
</template>

<script>
import { models } from '@am-area/sdk'
const { Guest, Ticket, TicketToken } = models
import Questions from '@/components/Questions.vue'
// library
import QuestionsData from '@/library/Questions'

import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
dayjs.extend(isBetween)

export default {
  components: {
    Questions,
  },
  async created() {
    await this.getData()
  },
  data: () => ({
    guest: {},
    hotel: {},
    tickets: [],
    // usedTicketsWithCount: [],
    ticketTokens: [],
    suggests: [],
  }),
  props: {
    categories: Array,
    area: Object,
  },
  computed: {
    guestId() {
      return this.$route.params.guestId
    },
    questions() {
      return QuestionsData
    },
  },
  methods: {
    async getData() {
  
      if (this.guestId) localStorage.setItem('guestId', this.guestId)

      try {
        let guest = await Guest.get(this.guestId, {
          with: 'hotel.area,bookmarksOnlyToday',
        })
        this.hotel = guest.hotel
        delete guest.hotel
        this.guest = guest
        this.$root.bookmarks_.splice(0)
        this.$root.bookmarkIds_.splice(0)
        this.$root.bookmarks_.push(...guest.bookmarks_only_today)
        this.$root.bookmarkIds_.push(
          ...guest.bookmarks_only_today.map((o) => o.id)
        )
        this.$root.lang_ = guest.lang

        this.$emit('set-guest', this.guest)
        await Promise.all([this.getSuggests(),this.getTicketTokens(),])
      } catch (e) {
        // this.$router.replace("/");
      } finally {
        this.viewLoading = false
        this.dataReady = true
      }
    },
    async getTicketTokens() {
     const tokens = await TicketToken.index({
        with: 'ticket.shop.exceptionalBusinessDays',
      })
     this.ticketTokens = tokens.sort((a, b) => a.created_at > b.created_at ? -1 : 1)
     this.tickets = this.ticketTokens.map((token) => token.ticket)   
   },
    // async getTickets() {
    //  this.tickets = await Ticket.index({
    //     with: 'shop.exceptionalBusinessDays,attributeTags',
    //   })
    // },
    // async getUsedTicketsWithCount() {
    //   const ticketTokens = await TicketToken.index()
    //   this.ticketTokens = ticketTokens
    //   //ゲストの使用したチケットIDを取得
    //   let filteredTokens = ticketTokens
    //     .filter((token) => token.guest_id == this.$route.params.guestId)
    //     .map((o) => o.ticket_id)
  
    //   //チケット毎の使用回数を取得
    //   let counter = {}
    //   filteredTokens.map(function (obj) {
    //     let key = obj
    //     counter[key] = (counter[key] || 0) + 1
    //   })
    //   let usedTickets = Object.entries(counter).map(([key, value]) => {
    //     return {
    //       ticket_id: key,
    //       count: value,
    //     }
    //   })
    //   this.usedTicketsWithCount.splice(0)
    //   this.usedTicketsWithCount.push(...usedTickets)
    //   this.getFilteredTickets()
    // },
    //最大使用可能回数を超えてないチケットのみ取得
    // getFilteredTickets() {
    //   const overLimitTickets = this.suggests.filter(
    //     (ticket) =>
    //       this.usedTicketsWithCount.filter(
    //         (count) =>
    //           count.ticket_id == ticket.id && count.count > ticket.limit
    //       ).length > 0
    //   )

    //   let results = this.suggests.filter(
    //     (ticket) => !overLimitTickets.map((e) => e.id).includes(ticket.id)
    //   )

    //   this.suggests.splice(0)
    //   this.suggests.push(...results)
    // },
    async getSuggests() {
      this.suggests.splice(0)
      const suggests = await Ticket.index({
        with: 'shop.exceptionalBusinessDays,attributeTags',
        suggest: true,
        limit: 20,
      })
      this.suggests.push(...suggests)
      // this.viewLoading = false
      // this.getUsedTicketsWithCount()
    },
    async updateGuest() {
      try {
        this.guest = await Guest.update(this.guestId, this.guest)
      } catch (e) {
        throw e
      } finally {
        this.viewLoading = false
      }
    },
  },
}
</script>
