<style>
.ticket .v-divider {
  border-style: dashed;
}
</style>

<template>
  <div v-if="dataReady" style="position: relative">
    <template v-if="ticket.id">
      <v-card
        class="ticket"
        :link="link"
        :to="link ? `/${$route.params.guestId}/tickets/${ticket.id}` : null"
      >
        <S3Image
          v-if="ticket.image_main"
          :path="ticket.image_main"
          :aspect-ratio="2 / 1"
          width="100%"
        />
        <v-img
          v-else
          :aspect-ratio="2 / 1"
          src="@/assets/noimage.png"
          width="100%"
        ></v-img>
        <v-card-title>{{ getLabel(ticket.name) }}</v-card-title>
        <template v-if="desc">
          <v-divider></v-divider>
          <v-card-text>
            <section style="white-space: pre-line">
              {{ getLabel(ticket.desc) }}
            </section>
            <section
              v-if="note"
              class="text-caption text--disabled mt-2"
              style="white-space: pre-line"
            >
              {{ getLabel(ticket.note) }}
            </section>
          </v-card-text>
        </template>
        <template v-if="true">
          <v-card-text class="grey--text text--darken-3">
            {{ getLabel(shop.name) }}
            <template v-if="showOpenInfo">
              <span class="ml-2 font-weight-bold" :class="openingStatus.class">
                {{ getLabel(openingStatus.text) }}
              </span>
              <span class="ml-2">{{ businessHours }}</span>
            </template>
          </v-card-text>
        </template>
        <template v-if="use && isValidCoupon">
          <v-divider></v-divider>
          <v-card-actions class="py-4">
            <v-spacer></v-spacer>
            <v-btn
              class="px-6 py-4 text-uppercase font-weight-bold"
              style="font-size: 0.8em"
              small
              elevation="0"
              color="primary"
              @click="$emit('use')"
            >
              {{ getLabel(locales.couponUse) }}
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </template>
        <template v-if="use && !isValidCoupon">
          <v-divider></v-divider>
          <v-card-actions class="py-4">
            <v-spacer></v-spacer>
            <v-btn
              class="px-6 py-4 text-uppercase font-weight-bold"
              style="font-size: 0.8em"
              small
              disabled
              elevation="0"
              color="primary"
              @click="$emit('use')"
            >
              {{ getLabel(locales.couponIsInvalid) }}
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </template>
      </v-card>

      <!-- お気に入り -->
      <v-btn
        dark
        icon
        style="position: absolute; top: 4px; right: 4px"
        @click="liked = !liked"
      >
        <v-icon v-if="liked">mdi-heart</v-icon>
        <v-icon v-else>mdi-heart-outline</v-icon>
      </v-btn>
    </template>
    <template v-else>
      <v-skeleton-loader type="card"></v-skeleton-loader>
    </template>
  </div>
</template>

<script>
import { models } from '@am-area/sdk'
const { Ticket } = models
import S3Image from '@/components/S3Image.vue'

export default {
  components: {
    S3Image,
  },
  async created() {
    await this.getData()
  },
  props: {
    ticket: Object,
    ticketTokens: Array,
    desc: Boolean,
    note: Boolean,
    link: Boolean,
    use: Boolean,
    guestEmail: String,
  },
  data: () => ({
    //
  }),
  computed: {
    shop() {
      return this.ticket.shop
    },
    ticketTokenCount() {
      const today = dayjs().format('YYYY-MM-DD')
      switch (this.ticket.limitType) {
        case 'DATE':
          return this.ticketTokens.filter(
            (o) =>
              o.ticket_id == this.ticket.id &&
              dayjs(o.created_at).format('YYYY-MM-DD') == today
          ).length
          break
        case 'STAY':
          return this.ticketTokens.filter((o) => o.ticket_id == this.ticket.id)
            .length
          break
        default:
      }
    },
    isValidCoupon() {
      if (this.ticket.limit > this.ticketTokenCount) {
        return true
      } else if (this.ticket.limit <= this.ticketTokenCount) {
        return false
      }
    },
    business() {
      // 特別営業時間は通常営業時間より優先となる
      const today = dayjs().format('YYYY-MM-DD')
      let exceptionalBusinessDays = this.shop.exceptional_business_days
        ? this.shop.exceptional_business_days.length
        : ''
      let exceptionalForToday = this.shop.exceptional_business_days.find(
        (o) => o.date == today
      )
      const dayToday = dayjs().format('ddd').toUpperCase()
      return exceptionalBusinessDays && exceptionalForToday
        ? this.shop.exceptional_business_days.filter((o) => o.date == today)
        : this.shop.day_of_week[dayToday]
    },
    showOpenInfo() {
      return (
        this.shop.show_open_info &&
        ((this.business[0].opening_time && this.business[0].closing_time) ||
          !this.business[0].is_open)
      )
    },
    businessHours() {
      if (!this.business[0].is_open) return ''
      let data = _.cloneDeep(this.business).sort((a, b) =>
        a.opening_time < b.opening_time ? -1 : 1
      )
      if (data.length == 1) {
        return `${data[0].opening_time} ~ ${data[0].closing_time}`
      } else {
        let businessHours = []
        data.map((d) =>
          businessHours.push(`${d.opening_time} ~ ${d.closing_time}`)
        )
        return businessHours.join(', ')
      }
    },
    openingStatus() {
      if (!this.business[0].is_open) {
        return {
          text: this.locales.businessHoliday,
          class: 'error--text',
        }
      }
      const current = dayjs().format('YYYY-MM-DD HH:mm')
      const today = dayjs().format('YYYY-MM-DD')
      let isOpenArr = []

      this.business.map((d) => {
        const openingTime = dayjs(today + '' + d.opening_time)
          .add(-1, 'minute')
          .format('YYYY-MM-DD HH:mm')
        const closingTime = today + '' + d.closing_time
        const isOpen = dayjs(current).isBetween(openingTime, closingTime)
        isOpenArr.push(isOpen)
      })

      return isOpenArr.includes(true)
        ? {
            text: this.locales.businessOpen,
            class: 'green--text text--darken-2',
          }
        : {
            text: this.locales.businessClosed,
            class: 'error--text',
          }
    },
    liked: {
      get() {
        return this.bookmarkIds.includes(this.ticket.id)
      },
      async set(bool) {
        const ticketId = this.ticket.id
        if (bool) {
          this.bookmarkIds.push(ticketId)
          await Ticket.bookmark(ticketId, {
            id: this.$route.params.guestId,
            email: this.guestEmail,
          })
        } else {
          const index = this.bookmarkIds.indexOf(ticketId)
          this.bookmarkIds.splice(index, 1)
          await Ticket.unbookmark(ticketId, {
            id: this.$route.params.guestId,
            email: this.guestEmail,
          })
        }
      },
    },
  },
  methods: {
    async getData() {
      this.scrollTop()
      this.dataReady = true
      this.$emit('shop-opening-status', this.openingStatus)
    },
    scrollTop() {
      window.scrollTo({
        top: 0,
        behavior: 'instant',
      })
    },
  },
}
</script>
