import Locales from '@/library/Lang/Lang.json'

export default {
  install(vue) {
    console.log("Installing validation plugin..");
    vue.mixin({
      computed: {
        formRules: {
          get() {
            return {
              required: (v) => !!v || this.getLabel(Locales.validationRequired),
              // email: (v) =>
              //   /.+@.+\..+/.test(v) ||
              //   this.$t("plugins.validation.invalidEmail"),
              // phone: (v) =>
              //   /\d+/.test(v) ||
              //   this.$t("plugins.validation.onlyNumericArrowed"),
            };
          },
        },
      },
      // methods: {
      //   checkKana() {
      //     return (v) => {
      //       const str = v == null ? "" : v;
      //       if (str.match(/^[ァ-ヶｦ-ﾟー　]+$/)) {
      //         return true;
      //       } else {
      //         return this.$t("plugins.validation.katakana");
      //       }
      //     };
      //   },
      //   checkHiragana() {
      //     return (v) => {
      //       const str = v == null ? "" : v;
      //       if (str.match(/^[ぁ-んー　]*$/)) {
      //         return true;
      //       } else {
      //         return "ひらがなで入力してください"
      //       }
      //     };
      //   },
      // },
    });
  },
};
