<template>
  <div>
    <v-dialog ref="dialog" v-model="modal" width="290px" >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="data"
          :label="label"
          prepend-icon="mdi-calendar"
          readonly
          v-bind="attrs"
          v-on="on"
          :rules="rules"
        ></v-text-field>
      </template>

      <v-card>
        <v-card-title>
          {{ label }}
        </v-card-title>
        <v-divider></v-divider>
        <v-date-picker
          v-model="data"
          :label="label"
          no-title
          scrollable
          :locale="lang"
          :day-format="(val) => pickerDayformat(val)"
          @input="modal = false"
        >
        </v-date-picker>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import dayjs from 'dayjs'

export default {
  props: {
    value: { type: String },
    rules: { type: Array, default: () => [] },
    label: { type: String, default: '' },
    disabled: { type: Boolean, default: false },
    min: { type: String, default: '' },
  },
  data: () => ({
    modal: false
  }),
  computed: {
    data: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
    start() {
      return dayjs(this.date).startOf('month').format('YYYY-MM-DD')
    },
    end() {
      return dayjs(this.date).endOf('month').format('YYYY-MM-DD')
    },
    minDate() {
      return dayjs().format('YYYY-MM-DD')
    },
  },
  methods: {
    pickerDayformat(val) {
      return dayjs(val).format('D')
    },
  }
}
</script>