export default {
  data() {
    return {
      lang_: '',
      bookmarks_: [],
      bookmarkIds_: [],
    }
  },
  methods: {
    //
  },
}
