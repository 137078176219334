<template>
  <v-app>
    <div v-if="!loading">
      <AppBar
        :categories="categories"
        :area="area"
        :guest="guest"
        @update-guest-lang="updateGuestLang($event)"
      />

      <v-main class="grey lighten-5 pb-6" style="min-height: 100vh">
        <v-app-bar dense color="primary" dark>
        <v-app-bar-title color="primary" class="text-h6">
          <div class="text-subtitle-1 font-weight-bold">{{ getLabel(area.coupon_page_title) }}</div>
        </v-app-bar-title>
        </v-app-bar>
        <router-view
          :categories="categories"
          :area="area"
          @set-guest="guest = $event"
        />
      </v-main>

      <footer>
        <v-sheet color="grey darken-1" class="white--text">
          <v-container>
            <v-row>
              <v-col class="text-center">
                <small>
                  copyright &copy paradigmshift inc. all right reserved.
                </small>
              </v-col>
            </v-row>
          </v-container>
        </v-sheet>
      </footer>
    </div>
  </v-app>
</template>

<script>
import AppBar from '@/components/AppBar.vue'
import { models } from '@am-area/sdk'

const { Category, Guest, Hotel } = models

export default {
  name: 'App',
  components: {
    AppBar,
  },
  async created() {
    await Promise.all([this.setGuest()])
    this.viewLoading = false
  },
  data: () => ({
    categories: [
      {
        id: '1',
        name: {
          en: 'All',
          ja: 'すべて',
          ko: '모두',
          zh_CN: '全部',
          zh_TW: '全部',
        },
      },
    ],
    guest: new Guest(),
    area: {},
    hotel: {},
    loading: true,
  }),
  computed: {
    showBackNavigation() {
      return this.$route.name
    },
    guestId() {
      return this.$route.params.guestId
    },
  },
  methods: {
    async setGuest() {
      if (this.guestId) {
        localStorage.setItem('guestId', this.guestId)
      }
      this.guest.id = localStorage.getItem('guestId')
      if (this.guest.id) {
        this.guest = await Guest.get(this.guest.id, {
          with: 'hotel.area',
        })
      }

      this.getCategories()
      this.loading = false
      this.lang = this.guest.lang
    },
    async getCategories() {
      if (this.loading) {
        this.$vuetify.theme.themes.light.primary = '#FFFFFF'
      }
      if (!this.guest.hotel_id) {
        //registerページ用
        this.hotel = await Hotel.get(this.$route.params.hotelId, {
          with: 'area.categories,area.shops.tickets',
        })
      } else {
        this.hotel = await Hotel.get(this.guest.hotel_id, {
          with: 'area.categories,area.shops.tickets',
        })
      }

      if (!this.hotel.area) return
      const categories = this.hotel.area.categories
      this.area = this.hotel.area
      this.$vuetify.theme.themes.light.primary = this.area.color
        ? this.area.color
        : '#EF5350'
      document.title = this.area.coupon_page_title.ja
      // this.categories.splice(0)
      this.categories.push(...categories)

      this.loading = false
    },
    // async getCategories() {
    //   const categories = await Category.index()
    //   this.categories.splice(0)
    //   this.categories.push(...categories)
    // },

    async updateGuestLang(langSelected) {
      this.lang = langSelected
      this.guest.lang = langSelected
      if (!this.guest.id) return
      await Guest.update(this.guest.id, { lang: langSelected })
    },
  },
}
</script>
