export default {
  install(vue) {
    console.log("Installing Constants plugin..");
    vue.mixin({
      computed: {
        LANGS() {
          return [
            { value: "ja", text: "日本語" },
            { value: "en", text: "English" },
            { value: "ko", text: "한국어" },
            { value: "zh_CN", text: "中文（簡）" },
            { value: "zh_TW", text: "中文（繁）" },
          ];
        },
        COMMON_SEX() {
          return {
            ja: [
              { value: "male", text: "男性" },
              { value: "female", text: "女性" },
              { value: "NA", text: "回答しない" },
            ],
            en: [
              { value: "male", text: "male" },
              { value: "female", text: "female" },
              { value: "NA", text: "No answer" },
            ],
            ko: [
              { value: "male", text: "남성" },
              { value: "female", text: "여성" },
              { value: "NA", text: "답변하지 않음" },
            ],
            zh_CN: [
              { value: "male", text: "男性" },
              { value: "female", text: "女性" },
              { value: "NA", text: "没有回答" },
            ],
            zh_TW: [
              { value: "male", text: "男性" },
              { value: "female", text: "女性" },
              { value: "NA", text: "不要回答" },
            ],
          }
        },
        COMMON_AGES() {
          return {
            ja: [
              { value: 1, text: "13才未満" },
              { value: 10, text: "13〜19才" },
              { value: 20, text: "20代" },
              { value: 30, text: "30代" },
              { value: 40, text: "40代" },
              { value: 50, text: "50代" },
              { value: 60, text: "60代" },
              { value: 70, text: "70代" },
              { value: 80, text: "80才以上" },
            ],
            en: [
              { value: 1, text: "Under 13" },
              { value: 10, text: "13-19 Years Old" },
              { value: 20, text: "20's" },
              { value: 30, text: "30's" },
              { value: 40, text: "40's" },
              { value: 50, text: "50's" },
              { value: 60, text: "60's" },
              { value: 70, text: "70's" },
              { value: 80, text: "Over 80 Years Old" },
            ],
            ko: [
              { value: 1, text: "13세 미만" },
              { value: 10, text: "13~19세" },
              { value: 20, text: "20대" },
              { value: 30, text: "30대" },
              { value: 40, text: "40대" },
              { value: 50, text: "50대" },
              { value: 60, text: "60대" },
              { value: 70, text: "70대" },
              { value: 80, text: "80세 이상" },
            ],
            zh_CN: [
              { value: 1, text: "13岁以下" },
              { value: 10, text: "13~19岁" },
              { value: 20, text: "20年代" },
              { value: 30, text: "30年代" },
              { value: 40, text: "40年代" },
              { value: 50, text: "50年代" },
              { value: 60, text: "60年代" },
              { value: 70, text: "70年代" },
              { value: 80, text: "80岁以上" },
            ],
            zh_TW: [
              { value: 1, text: "13歲以下" },
              { value: 10, text: "13~19歲" },
              { value: 20, text: "20年代" },
              { value: 30, text: "30年代" },
              { value: 40, text: "40年代" },
              { value: 50, text: "50年代" },
              { value: 60, text: "60年代" },
              { value: 70, text: "70年代" },
              { value: 80, text: "80歲以上" },
            ],
          }
        },
      },
    });
  },
};
