<template>
  <div>
    <section>
      <!-- HERO -->
      <header>
        <template>
          <v-carousel v-if="area.image_url.heroImages.length" height="70vh">
            <v-carousel-item
              v-for="(image, i) in area.image_url.heroImages"
              :key="i"
            >
              <S3Image
                :path="image"
                :aspect-ratio="2 / 1"
                class=""
                height="70vh"
                width="100%"
              />
            </v-carousel-item>
          </v-carousel>
          <v-img
            v-else
            :aspect-ratio="2 / 1"
            src="@/assets/noimage.png"
            height="70vh"
            width="100%"
          ></v-img>
        </template>
      </header>
      <v-toolbar flat>
        <v-container>
          <div class="mx-n4 mx-md-auto">
            <section>
              <div>{{ getLabel(hotel.name) }}</div>
              <div v-if="lang == 'en'">
                {{ `${guest.given_name} ${guest.family_name}` }}
              </div>
              <div v-else>
                {{ `${guest.family_name} ${getLabel(locales.nameTitle)}` }}
              </div>
            </section>
          </div>
        </v-container>
      </v-toolbar>
      <v-container class="pt-0">
        <!-- カテゴリ -->
        <section class="mt-8">
          <v-row dense>
            <v-col
              v-for="(cat, i) in categories"
              :key="i"
              cols="4"
              sm="3"
              md="2"
            >
              <v-card
                link
                class="px-2 py-4 text-center"
                :to="`/${$route.params.guestId}/tickets?cat=${cat.id}`"
              >
                <div>
                  <v-icon large color="primary" class="mr-2">mdi-star</v-icon>
                </div>
                <div class="text-caption mt-2">
                  {{ getLabel(cat.name) }}
                </div>
              </v-card>
            </v-col>
          </v-row>
        </section>
        <section v-if="suggests.length" class="mt-8">
          <header class="font-weight-bold">
            {{ getLabel(locales.coupons) }}
          </header>
          <div class="mt-4">
            <v-row>
              <v-col
                v-for="(ticket, i) in suggests"
                :key="i"
                xl="3"
                lg="4"
                md="6"
                sm="6"
                cols="12"
              >
                <TicketComponent
                  link
                  :ticket="ticket"
                  :ticketTokens="ticketTokens"
                  :guestEmail="guest.email"
                />
              </v-col>
            </v-row>
          </div>
        </section>
      </v-container>
    </section>
  </div>
</template>

<script>
import TicketComponent from '@/components/Ticket.vue'
import S3Image from '@/components/S3Image.vue'

export default {
  name: 'Home',
  components: {
    TicketComponent,
    S3Image,
  },
  async created() {
    this.scrollTop()
    this.dataReady = true
    // this.getData()
  },
  props: {
    guest: Object,
    hotel: Object,
    area: Object,
    categories: Array,
    suggests: Array,
    ticketTokens: Array,
    // getData: Function,
  },
  data: () => ({
    //
  }),
  methods: {
    scrollTop() {
      window.scrollTo({
        top: 0,
        behavior: 'instant',
      })
    },
  },
}
</script>
