<template>
  <div>
    <v-app-bar app color="primary" flat dark>
      <v-slide-x-transition mode="out-in">
        <div>
          <v-btn
            v-if="$route.meta.backPath"
            outlined
            class="text-uppercase "
            @click="$router.push({ name: 'home' })"
          >
            <v-icon>mdi-home</v-icon>
            <!-- {{ getLabel(locales.back) }} -->
          </v-btn>
          <!-- <v-btn v-if="$route.meta.backPath" outlined :to="$route.meta.backPath"> -->
        </div>
      </v-slide-x-transition>

      <v-spacer></v-spacer>
      <v-btn
        v-if="$route.params.guestId"
        outlined
        :to="{
          name: 'used-tickets',
        }"
      >
        <v-icon>mdi-ticket-outline</v-icon>
        <!-- 使用済みクーポン -->
      </v-btn>
      <v-btn
        v-if="$route.params.guestId"
        outlined
        class="ml-2"
        :to="{
          name: 'favorites',
        }"
      >
        <v-icon>mdi-heart</v-icon>
        <!-- お気に入り -->
      </v-btn>

      <div class="text-center" v-if="area.language">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn outlined class="ml-2" v-bind="attrs" v-on="on">
              <v-icon left>mdi-translate</v-icon>
              {{
                area.language.find((d) => d.value == langSelected).text ||
                '日本語'
              }}
            </v-btn>
          </template>
          <v-list>
            <v-list-item-group v-model="langSelected" color="primary">
              <v-list-item
                v-for="(la, i) in area.language"
                :key="i"
                :value="la.value"
              >
                <v-list-item-title>{{ la.text || '日本語' }}</v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
      </div>
      <template v-slot:extension v-if="$route.name == 'tickets'">
        <v-tabs
          v-model="tabs"
          dark
          centered
          show-arrows
          background-color="primary darken-1"
        >
          <v-tab
            v-for="(cat, i) in categories"
            :key="i"
            @change="updateCategory(cat.id)"
          >
            {{ getLabel(cat.name) }}
          </v-tab>
        </v-tabs>
      </template>
    </v-app-bar>
  </div>
</template>

<script>
import * as SDK from '@am-area/sdk'

export default {
  data: () => ({}),
  props: {
    categories: Array,
    guest: Object,
    area: Object,
  },
  computed: {
    tabs: {
      get() {
        const index = this.categories.findIndex(
          (d) => d.id == this.$route.query.cat
        )
        return index
      },
      set(v) {
        this.$emit('input', v)
      },
    },
    langSelected: {
      get() {
        return this.lang || 'ja'
      },
      set(v) {
        this.$emit('update-guest-lang', v)
      },
    },
  },
  methods: {
    updateCategory(v) {
      const query = _.cloneDeep(this.$route.query)
      query.cat = v
      this.$router.replace({ query: query })
    },
  },
}
</script>
